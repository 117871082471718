import useI18next from 'services/i18n';
import { Outlet } from 'react-router-dom';

import { isAuthenticationCompleteSelector } from 'store/auth/selectors';

import { useAnalytic } from 'services/analytics/useAnalytic';

import { useAuthentication, useSelector } from 'hooks';

import { PageLoader } from 'components/PageLoader';
import Modals from 'components/Modals';

const RootLayout = () => {
    const isComplete = useSelector(isAuthenticationCompleteSelector);
    const { isTranslationsLoaded } = useI18next();

    useAuthentication();
    useAnalytic();

    return isTranslationsLoaded && isComplete ? (
        <>
            <Outlet />
            <Modals />
        </>
    ) : (
        <PageLoader />
    );
};

export default RootLayout;
