import config from 'config';

import { gridBreakpoints } from 'theme/mediaQueries';

export const isEmpty = (value: null | undefined | string | number | object): boolean => {
    return (
        value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0) ||
        (typeof value === 'number' && Number(value) === Infinity)
    );
};

export const getParamFromUrl = (paramName: string) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(paramName);
};

export const replaceUnderscoreToDash = (string: string): string => string.replace(/_/g, '-');

export const getWindowSize = (params = '(min-width: 1024px)') => {
    const mediaQuery = window.matchMedia(params);

    return mediaQuery.matches;
};

export const isTablet = () => getWindowSize(`(min-width: ${gridBreakpoints.tablet}px)`);

export const isProd = () => config.ENV === 'prod';
