import { styled } from 'styled-components';

import { PillWrapper } from 'components/Pill/styled';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const ModalContent = styled(PillWrapper)`
    padding: 40px 0;
`;
