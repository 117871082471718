import { Button } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import routes from 'router/routes';

import { sendAnalyticMarkAsCompleteClick, sendPlayerTrainingComplete } from 'services/analytics/trackers/workoutPlayer';
import { trackScreenLoad } from 'services/analytics/trackers/mainTrackers';

import { useGetPPCommonAnalyticsData } from 'hooks';

import Modal from 'components/Modal/Modal';

import * as S from './styled';

import { QuitModalPropsType } from './types';

const QuitWorkoutModal = ({
    closeModal,
    handlePlayButton,
    handleCompleteWorkout,
    totalProgress,
    currentExercise,
}: QuitModalPropsType) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isMarkAsCompleted, setMarkAsCompleted] = useState<boolean>(false);

    const commonAnalyticDate = useGetPPCommonAnalyticsData();

    useEffect(() => {
        trackScreenLoad('player_interrupt', {
            ...commonAnalyticDate,
            workout_part: currentExercise.block_type,
            fact_duration: totalProgress,
        });
    }, []);

    const handleQuit = () => {
        sendPlayerTrainingComplete({
            ...commonAnalyticDate,
            completion_type: 'close',
            fact_duration: totalProgress,
        });

        closeModal();
        navigate(routes.PRIVATE.PERSONAL_PLAN.path);
    };

    const handleComplete = () => {
        sendPlayerTrainingComplete({
            ...commonAnalyticDate,
            completion_type: 'finish',
            fact_duration: totalProgress,
        });
        closeModal();
        handleCompleteWorkout();
    };

    const handleResume = () => {
        closeModal();
        handlePlayButton();
    };

    const handleMarkAsCompleted = () => {
        sendAnalyticMarkAsCompleteClick({
            ...commonAnalyticDate,
            exercise_name: currentExercise.name,
            exercise_id: currentExercise.exercise_id,
            complete: !isMarkAsCompleted,
            fact_duration: totalProgress,
            workout_part: currentExercise.block_type,
        });

        setMarkAsCompleted(!isMarkAsCompleted);
    };

    return (
        <Modal isOpen shouldCloseOnOverlayClick paddingX={20} paddingY={0} customContent>
            <S.ModalWrapper>
                <S.Content>
                    <S.Title medium type="h4" mb={12} text={t('workout.quitWorkoutModal.title')} />
                    <S.Subtitle type="large-text" mb={24} text={t('workout.quitWorkoutModal.subtitle')} />
                    <S.Checkbox
                        dataLocator="completeButton"
                        titleType="medium-button"
                        title={t('workout.quitWorkoutModal.markAsCompleted')}
                        value=""
                        leftCheckbox
                        onClick={handleMarkAsCompleted}
                    />
                    <S.ButtonWrapper>
                        {isMarkAsCompleted ? (
                            <Button
                                dataLocator="successfullyCompleteDayButton"
                                text={t('workout.quitWorkoutModal.completeBtn')}
                                onClick={handleComplete}
                            />
                        ) : (
                            <>
                                <Button
                                    dataLocator="quitButton"
                                    text={t('workout.quitWorkoutModal.quitBtn')}
                                    backgroundColor="surface-main"
                                    onClick={handleQuit}
                                />
                                <Button
                                    dataLocator="resumeButton"
                                    text={t('workout.quitWorkoutModal.resumeBtn')}
                                    onClick={handleResume}
                                />
                            </>
                        )}
                    </S.ButtonWrapper>
                </S.Content>
            </S.ModalWrapper>
        </Modal>
    );
};

export default QuitWorkoutModal;
