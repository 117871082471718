import { styled } from 'styled-components';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const ModalContent = styled.div`
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
