import { selectCurrentDay, selectPlanDays, selectYogaType } from 'store/personalPlan/selectors';

import { useSelector } from '../store';

const useGetPPCommonAnalyticsData = () => {
    const currentDay = useSelector(selectCurrentDay);
    const planDays = useSelector(selectPlanDays);
    const yogaType = useSelector(selectYogaType);

    if (planDays.length === 0) return;

    const { focus, focus_zones, level } = planDays[currentDay - 1];

    return {
        plan_day: currentDay,
        focus,
        focus_zones,
        exercise_level: level,
        yoga_type: yogaType,
        exercise_source: 'plan',
    };
};

export default useGetPPCommonAnalyticsData;
